.setting-img{
	height: fit-content;
}
.img-add{
	top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.fa-camera{
	width: 30px!important;
}
