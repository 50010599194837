:root {
    --amplify-primary-color: #3182ce;
    --amplify-primary-tint: #3182ce;
    --amplify-primary-shade: #3182ce;
}
.fit-content{
	width: fit-content;
}

/* 好きな技術選択プラス */
.add-plus{
	right:40px;
	top: 33px;
	margin: auto 40px auto auto;
}
.check-box{
	right:30px;
	top: 18px;
}
