.showIcon{
	width: 4%;
}
.h-92{
	height: 92%;
}

.video_wrapper {
		position: relative;
		height: 75%;
}
.video_wrapper:not(.js_active)::before {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
}

