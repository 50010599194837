input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
  -moz-appearance:textfield;
}

.home-margin-top{
  margin-top: 29rem;
}

.toggle-path {
    transition: background 0.3s ease-in-out;
}
.toggle-circle {
    top: 0.2rem;
    left: 0.25rem;
    transition: all 0.3s ease-in-out;
}
/* カード情報を保存するトグル */
input:checked ~ .toggle-circle {
    transform: translateX(100%);
}
input:checked ~ .toggle-path {
    background-color:#7f9cf5;
}

/* 配信設定のトグル */
input:checked ~ .toggle-circle {
    transform: translateX(100%);
}
input:checked ~ .toggle-path {
    background-color:#7f9cf5;
}

.bg-opacity-80{
  --bg-opacity: 0.8!important;
}
/* image-crop */
.ReactCrop__image{
  height: 300px;
  /* width: 300px; */
  /* object-fit: cover; */
}

/* .ReactCrop .ReactCrop__crop-selection {
  border-radius: 50%;
} */
.bg-beige{
  background-color: #fdfbef;
}

.bg-chat{
  background-color: #f9f7f6;
}

.text-home-color{
  color:#433f36;
}

.user_list a {
  width: 30%;
}
.user_list a:nth-child(1) .ribbon{
  background:#ffdd5b;
}
.user_list a:nth-child(1) .ribbon:after{
  border-left: 15px solid #ffdd5b;
  border-right: 15px solid #ffdd5b;
}
.user_list a:nth-child(2) .ribbon{
  background:#c9caca;
}
.user_list a:nth-child(2) .ribbon:after{
  border-left: 15px solid #c9caca;
  border-right: 15px solid #c9caca;
}
.user_list a:nth-child(3) .ribbon{
  background:#bf5b11;
}
.user_list a:nth-child(3) .ribbon:after{
  border-left: 15px solid #bf5b11;
  border-right: 15px solid #bf5b11;
}
/* ランキングのリボン */
.ribbon {  
  padding: 2px 0;
  width: 30px;
  font-size: 14px;
  background:#606973;
  border-radius: 2px 2px 0px 0px;
}

.ribbon:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  height: 0;
  width: 0;
  border-left: 15px solid #606973;
  border-right: 15px solid #606973;
  border-bottom: 8px solid transparent;
}
/* 概要欄のaタグ */
.summary a{
  color:rgb(29, 155, 240)
}
/* スクロールバーを非表示にする */
.hash_tag{
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}
.hash_tag::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}

.gray_out{
	-webkit-filter: grayscale(1); /* Webkit */
	filter: gray; /* IE6-9 */
	filter: grayscale(1); /* W3C */
	cursor: pointer;
}

/* viewingページで使う */
.video_viewing{
  height: 70%;
}
/* vieingtitle */
._title{
  background: #c9e2ff;
  color: #153c6e;
}
/* LPで使用する色 */
.about_color{
  color: #0f4463;
}
.about_background{
  background-color:#e6f2fe;
}
.circle_background{
  background-color: #1e7198;
}

/* マイページでのコンテンツ */
.mypage_content{
  min-height: 300px;
}
.br_title{
  display: block;
}

/* successアイコンアニメーション */
.toggleButton {
  cursor: pointer;
  display: block;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: transform 0.14s ease;
}
.toggleButton:active {
  transform: rotateX(30deg);
}
.toggleButton input {
  display: none;
}
.toggleButton input + div {
  border: 3px solid #48bb78;
  border-radius: 50%;
  position: relative;
  width: 35px;
  height: 35px;
}
.toggleButton input + div svg {
  fill: none;
  stroke-width: 4;
  stroke: #48bb78;
  stroke-linecap: round;
  stroke-linejoin: round;
  width: 35px;
  height: 35px;
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  z-index: 1;
  stroke-dashoffset: 124.6;
  stroke-dasharray: 0 162.6 133 29.6;
  transition: all 0.4s ease 0s;
}
.toggleButton input + div:before {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  animation: bounceInBefore 0.3s linear forwards 0.3s;
}
.toggleButton input + div:after {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  animation: bounceInAfter 0.3s linear forwards 0.3s;
}
.toggleButton input:checked + div svg {
  stroke-dashoffset: 162.6;
  stroke-dasharray: 0 162.6 28 134.6;
  transition: all 0.4s ease 0.2s;
}
.toggleButton input:checked + div:before {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  animation: bounceInBeforeDont 0.3s linear forwards 0s;
}
.toggleButton input:checked + div:after {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  animation: bounceInAfterDont 0.3s linear forwards 0s;
}

@keyframes bounceInBefore {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }
  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}
@keyframes bounceInAfter {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }
  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}
@keyframes bounceInBeforeDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
}
@keyframes bounceInAfterDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
}
*:before, *:after {
  box-sizing: inherit;
}

/* ローディングぷろぐれすバーのやつ */
.loader-dots div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
    left: 8px;
    animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
    left: 8px;
    animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
    left: 32px;
    animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
    left: 56px;
    animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loader-dots3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loader-dots2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

@media screen and (max-width: 480px) {
  .home-margin-top{
    margin-top: 25rem;
  }
  /* viewingページで使う */
  .video_viewing{
    height: 16rem;
  }
/* 480pxまでの幅の場合に適応される */
  .user_list a {
    width: 45%;
  }
  .ribbon {
      width: 25px;
      font-size: 13px;
  }
  .user_list a:nth-child(1) .ribbon:after{
    border-left: 12.5px solid #ffdd5b;
    border-right: 12.5px solid #ffdd5b;
  }
  .user_list a:nth-child(2) .ribbon:after{
    border-left: 12.5px solid #c9caca;
    border-right: 12.5px solid #c9caca;
  }
  .user_list a:nth-child(3) .ribbon:after{
    border-left: 12.5px solid #bf5b11;
    border-right: 12.5px solid #bf5b11;
  }
  .ribbon:after {
    border-left: 12.5px solid #606973;
    border-right: 12.5px solid #606973;
  }
  .br_title{
    display: block;
  }
  .triangle-summary:after{
    content: "";
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color:rgba(229, 231, 235, var(--tw-text-opacity)) transparent transparent transparent;
    --tw-text-opacity: 1;
    border-width: 20px 20px 0 20px;
    z-index: 2;
  }
}
/* iphoneSEとかのサイズ */
@media screen and (max-width: 320px) {
  .br_title{
    display: none;
  }
}
